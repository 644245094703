import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:flex md:items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Anchor = _resolveComponent("Anchor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Paragraph, { class: "md:mr-6 text-gray-600 text-base" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Anchor, {
      class: "mt-8 md:mt-0 text-base",
      route: _ctx.route
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.routeText), 1)
      ]),
      _: 1
    }, 8, ["route"])
  ]))
}