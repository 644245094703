

    import { defineComponent, onBeforeMount, PropType, onBeforeUnmount } from 'vue'
    import { SeoInterface } from '../Interfaces/SeoInterface'
    import { useRoute } from 'vue-router'
    import { useTranslation } from '../compositions/UseTranslation'

    interface StructuredDataInterface {
        value?: string,
        code?: string,
        mode?: string,
    }

    interface meta {
        name: string,
        content: string
    }

    export default defineComponent({
        name: 'MetaInfo',
        props: {
            seo: { type: Object as PropType<SeoInterface>, required: true },
            structuredData: { type: Object as PropType<StructuredDataInterface> },
            published: { type: String, required: true },
            modified: { type: String, required: true },
            meta: { type: Array as PropType<Array<meta>>, default: () => [] }
        },
        setup(props) {

            const route = useRoute()
            const { isCNLocale } = useTranslation()

            let metaElements = []

            const seoValues: Record<string, Record<string, string>> = {
                twitter: {
                    card: props.seo?.twitter_card,
                    site: `@${ props.seo?.twitter_handle }`,
                    title: props.seo?.og_title,
                    description: props.seo?.description,
                    creator: `@${ props.seo?.twitter_handle }`
                },
                og: {
                    title: props.seo?.og_title,
                    type: 'article',
                    url: window.location.href,
                    image: props?.seo?.image?.url,
                    'image:alt': props?.seo?.image?.alt,
                    description: props.seo?.description,
                    site_name: props.seo?.site_name
                }
            }

            const createMetaElement = (name: string, value: string, isItemProp = false) => {

                if (value) {

                    const attribute = isItemProp ? 'itemprop' : 'name'
                    const metaItem: HTMLMetaElement = document.querySelector(`meta[${ attribute }="${ name }"]`) ?? document.createElement('meta')

                    metaItem.setAttribute(attribute, name)
                    metaItem.content = value

                    return metaItem

                }

            }

            const createLinkTag = (id: string, attributes: Record<string, string>) => {

                const tag: HTMLLinkElement = document.querySelector(`link[id="${ id }"]`) ?? document.createElement('link')

                tag.setAttribute('id', id)

                for (const attribute in attributes) {

                    tag.setAttribute(attribute, attributes[attribute])

                }

                return tag

            }

            onBeforeMount(() => {

                document.title = props?.seo?.compiled_title

                const domain = isCNLocale.value ? 'zh.digitalcreative.cn' : 'digitalcreative.cn'

                metaElements = [
                    createMetaElement('description', props?.seo?.description ?? props?.seo?.compiled_title),
                    createMetaElement('description', props?.seo?.description ?? props?.seo?.compiled_title, true),
                    createMetaElement('name', props.seo.title, true),
                    createMetaElement('image', props?.seo?.image?.url ?? '', true),
                    createMetaElement('datePublished', props?.published?.substring(0, 10), true),
                    createMetaElement('dateModified', props?.modified?.substring(0, 10), true),
                    createLinkTag('canonical', {
                        rel: 'canonical',
                        href: `https://${ domain }${ route.fullPath }`
                    }),
                    createLinkTag('en-href', {
                        rel: 'alternate',
                        hreflang: 'en',
                        href: `https://digitalcreative.cn${ route.fullPath }`
                    }),
                    createLinkTag('zh-href', {
                        rel: 'alternate',
                        hreflang: 'zh',
                        href: `https://zh.digitalcreative.cn${ route.fullPath }`
                    }),
                    createLinkTag('x-default', {
                        rel: 'alternate',
                        hreflang: 'x-default',
                        href: `https://digitalcreative.cn${ route.fullPath }`
                    })
                ]

                for (const meta in props.meta) {

                    metaElements.push(createMetaElement(meta.name, meta.content))

                }

                for (const site in seoValues) {

                    for (const item in seoValues[site]) {

                        metaElements.push(createMetaElement(`${ site }:${ item }`, seoValues[site][item]))

                    }

                }

                for (const element of metaElements) {

                    if (element) {

                        document.head.appendChild(element)

                    }

                }

                if (props.structuredData && props.structuredData.code !== null) {

                    const existingScript = document.querySelector('script[type="application/ld+json"]')
                    const structuredData = props?.structuredData?.code

                    if (structuredData) {

                        const structuredDataElement = new DOMParser().parseFromString(structuredData, 'text/xml')

                        // console.log(structuredDataElement)

                        if (existingScript) {

                            existingScript.replaceWith(structuredDataElement.firstChild!)

                        } else {

                            document.head.appendChild(structuredDataElement.firstChild!)

                        }

                    }

                }

            })

            onBeforeUnmount(() => {

                for (const element of metaElements) {

                    if (element) {

                        document.head.removeChild(element)

                    }

                }

            })

            return () => []

        }
    })

