

    import { computed, defineComponent } from 'vue'
    import { useRouter } from 'vue-router'
    import Anchor from './Anchor.vue'
    import Paragraph from './Paragraph.vue'
    import { Locale } from '../enums/Locale'
    import { useTranslation } from '../compositions/UseTranslation'

    export default defineComponent({
        name: 'HeaderAction',
        components: { Paragraph, Anchor },
        props: {
            title: {
                type: String, required: true
            },
            route: {
                type: String, required: true
            },
            routeText: {
                type: String, required: true
            }
        },
        setup(props) {

            const { isCNLocale } = useTranslation()
            const router = useRouter()

            const routeParameters = computed(() => ({
                name: props.route.name || props.route,
                params: { locale: isCNLocale ? Locale.CHINESE : '', ...props.route.params }
            }))

            return {
                isCNLocale,
                goToRoute() {

                    router.push(routeParameters.value)

                }
            }

        }
    })

