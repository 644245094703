

    import { defineComponent } from 'vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import HeaderAction from '../components/HeaderAction.vue'
    import { useTheme } from '../compositions/UseTheme'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useTranslation } from '../compositions/UseTranslation'

    export default defineComponent({
        name: 'NotFound',
        components: {
            PageHeader,
            ThemeChanger,
            MetaInfo,
            Column,
            Grid,
            HeaderAction
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { notFound } = useTranslation()

            return {
                Route,
                Theme,
                cms: notFound
            }

        }
    })

